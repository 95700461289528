.tob-bar-nav {
    display: flex;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid rgba(221, 229, 235, .5);
    padding: 0.5% 0%;

}

.strt-nav {
    width: 10%;
    margin-left: 1%;
}

.mid-menu-nav {
    width: 65%;

}

.mid-menu-nav ul {
    display: flex;
    margin-bottom: 0px;
}

.mid-menu-nav ul li {
    list-style-type: none;
    margin-right: 34px;
    padding: 1% 1%;

}

.mid-menu-nav ul li a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #465166;
    cursor: pointer;
    
}

/* .mid-menu-nav ul li:hover{
    background-color: #FAFAFA;
    border-radius: 16px;
    border: 1px solid #F99853;
} */

.active{
    background-color: #FAFAFA;
    border-radius: 16px;
    border: 1px solid #F99853;
    padding: 10px;
}

.login-area {
    width: 25%;
}

.list-item-inner {
    display: flex;
    align-items: center;
}

.login-area {
    display: flex;
    justify-content: end;
}

.login-area a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #465166;
    cursor: pointer;
    padding-right: 10%;
}

.list-item-inner svg {
    color: #F99853;
}

.strng-nme {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #002441;
    cursor: pointer;
}

.hero-inn {
    display: flex;
    padding: 3%;
}

.hero-inner {
    width: 100%;
}

.dynamic-con {
    background-color: #fff;
    width: 77%;
    margin: 7% 0% 7% 9%;
    border-radius: 36px;
}

.dyn-con-pad {
    padding: 50px 24px;
}

.inp-con input {
    padding: 0 24px;
    border: 1px solid #dde5eb;
    border-radius: 8px;
    background: transparent;
    color: #465166 !important;
    font-weight: 500;
    box-shadow: none;
    height: 48px;
    width: 100%;
    text-align: center;
    font-size: 24px;

}

.inp-con input:focus {
    outline: orange;
}

.btn-cls {
    color: #fff;
    font-weight: 600;
    background-color: #F99853;
    font-size: 14px;
    border: none;
    border-radius: 12px;
    height: 48px;
    width: 100%;
}

.inp-con {
    width: 100%;
    margin: 5% 0%;
}

.dyn-title h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #002441;
    margin: 0 0 32px;
}

.brand-con-inn-row_1 {
    display: flex;
    margin: 5% 0%;
}

.brand-con-inn-row_1 .brand-img {

    color: #465166 !important;
    padding: 2%;
    margin: 0px 10px;
    border-radius: 4px;
    background: #F5F5F5;

}

.brand-con-inn-row_2 {
    display: flex;
}

.brand-con-inn-row_2 .brand-img {

    color: #465166 !important;
    padding: 2%;
    margin: 0px 10px;
    border-radius: 4px;
    background: #F5F5F5;
}

.dyn-para {
    color: #465166;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    margin-top: 20px;
}

.hero-title_1 {
    width: 40%;
}

.hero-title_1 h1 {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: -.01em;
}

.hero-title_1 p {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.hero-title_1 {

    margin-bottom: 0%;
    margin-left: 0%;
    margin-top: 0%;
    margin-right: 10%;
    background-color: #0000006b;
    padding: 16px;
    border-radius: 6px;
    padding: 0px 30px;
}

.her_vrt {
    display: flex;
    align-items: end;
    justify-content: end;
}

.strt-nav img {
    width: 80%;
    /* width: 100px; */
}

.row_sell_step {
    display: flex;
    justify-content: space-between;

}

.hero-cn {
    background-position: center;
}

.row_sell_step-cons {
    width: 28%;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0 4px 12px rgba(126, 133, 148, .08);
    position: relative;

}

.div_for_pad {
    padding: 6% 6% 0% 6%;
}

.car_proc_sell_title h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #002441;
    margin: 0 0 40px;
    text-align: center;
}

.sel-cr-imgs img {
    object-fit: cover;
    width: 100%;
    height: 210px;
}

.sel_innr_content span {

    font-size: 52px;
    font-weight: 800;
    padding: 10px 17px;
    background-color: #fff;
    border-radius: 50%;
    color: #c2dbf1;
    position: relative;
    top: -34px;
    left: 20px;
}

.sel_innr_content h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 12px;
}

.sel_innr_content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    opacity: .8;
}

.why_cars_row {
    display: flex;
    justify-content: space-between;
}

.why_cars_row_innr {
    width: 28%;
    border-radius: 20px;
}

.why_cars_row_innr {
    display: flex;
    background-color: rgb(250, 250, 250);
}

.w_car_info h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: rgb(70, 81, 102);

}

.w_car_info p {
    margin: 20px 14px 20px 0px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    mix-blend-mode: normal;
    opacity: 0.8;
    color: rgb(70, 81, 102);

}

.why-car-log {
    margin: 8%;
}

.why_cars_title h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #002441;
    margin-bottom: 40px;
    text-align: center;
}

.blog-slidr {
    display: flex;
    justify-content: space-between;
}

.blog-slidr .blog-cntn {
    width: 28%;
    border-radius: 20px;
    box-shadow: rgba(126, 133, 148, 0.08) 0px 4px 42px;
}

.blog-top-media img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
}

.below-inf {
    padding: 4%;
    border: 1px solid #F99853;
    border-top: none;
    transition: 0.3s;
}

.below-inf:hover {
    background: #F99853;
}

.stprt {
    text-align: center;
    margin-bottom: 0px;
    padding: 1%;
    font-size: 16px;
    color: #fff;
    position: absolute;
    padding: 10px;
    background: #f99853;
    border-radius: 50%;
    width: 11%;
    bottom: 90%;
}

.below-inf:hover span {
    color: #FFF;

}

.below-inf:hover h3 {
    color: #FFF;

}

.below-inf:hover p {
    color: #FFF;

}

.swiper-pagination {
    display: none;
}

.blog-date span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgb(70, 81, 102);
    opacity: 0.8;

}

.below-inf h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgb(70, 81, 102);
    margin: 24px 0px 12px;
}

.below-inf p {
    font-weight: 400;
    color: rgb(126, 133, 148);

}

.prsn_name h4 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin: 0px;
    color: rgb(0, 36, 65);
}

.blog-tp-title h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #002441;
    margin-bottom: 40px;
    text-align: center;
}

.cstmrs-titl h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #002441;
    margin-bottom: 40px;
    text-align: center;
}

.brand-img img {
    width: 80px;
    object-fit: cover;
    height: 44px;

}

.brand-img span a {
    color: #F99853;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
}

.brand-img {
    display: flex;
}

.motivte_flex_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fst-sec {
    width: 22%;
    padding: 1%;
}

.fst-sec h3 {
    font-size: 36px;
    line-height: 46px;
    color: #00ba67;
    margin: 0;
    font-weight: 700;
}

.fst-sec h4 {
    margin: 12px 0 28px;
    font-size: 24px;
    line-height: 28px;
    color: #002441;
}

.satisfy-cstmr-slider {
    display: flex;
    justify-content: space-evenly;
}

.div_for_pad_t {
    padding: 6%;
}

.cstmr-slider-con {
    background-color: #F8F9F9;
    margin: 0px 10px;
    border-radius: 20px;
    padding: 5%;
}

.test_sld svg {
    color: #F99853;
    font-size: 16px;
}

.cntct_name span svg {
    color: #F99853;
}

.test_sld span {
    display: block;
    opacity: .7;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #465166;
}

.pro-pic img {
    width: 100%;
    object-fit: cover;
    height: auto;
    border-radius: 50%;
}

.sldr_media {
    display: flex;
    align-items: center;
}

.pro-pic {
    width: 20%;
}

.pro_pic_inf {
    margin-left: 5%;
}

.pro_pic_inf p {
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #002441;
}

.pro_pic_inf span {
    opacity: .7;
    font-size: 14px;
    color: #465166;
}

.section_car {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6%;
}

.only_carcon {
    margin-left: 7%;
}

.only_carcon h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -.01em;
    color: #153655;
    margin: 0 0 56px;
}

.mid_size_secbtn {
    color: #fff;
    font-weight: 600;
    background-color: #F99853 !important;
    font-size: 12px;
    letter-spacing: .01em;
    height: 48px;
    border: none;
    border-radius: 10px;
    padding: 0px 40px;
    border: none !important;
}
.insp-btns_secbtn {
    color: #fff;
    width: 40%;
    font-weight: 600;
    background-color: #F99853 !important;
    font-size: 12px;
    letter-spacing: .01em;
    height: 48px;
    border: none;
    border-radius: 10px;
    padding: 0px 40px;
    border: none !important;
}

.footr_con {
    display: flex;
    background-color: #F5F5F5;
    justify-content: center;
}

.footr_ {
    background-color: #F5F5F5;
    width: 22%;
    margin: 0px 2px;
    display: flex;
    justify-content: center;
}

.div_for_pad_f {
    padding-top: 6%;
}

.social_icn {
    display: flex;
    align-items: center;

}

.social_icn img {
    margin-right: 10px;
}

.f_row_t {
    display: flex;
}

.f_row_t {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #002441;
    text-transform: uppercase;
}

.f_row_t a span img {
    width: 70%;
}

.footr_ ul {
    padding-left: 0px;
}

.footr_ ul li {
    list-style-type: none;

    margin: 0px 10px 0px 0px;
}

.footr_ ul li a {
    text-decoration: none;
    color: #808080;
}

.footr_ h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #002441;
    text-transform: uppercase;
}

.footr_con {
    padding: 3%;
}

.footr_ p {
    color: #808080;
    line-height: 28px;

}

.footr_ span {
    color: #808080;

}

.div_for_pad_acc {
    padding: 6% 6% 6% 6%;
    background-color: #F5F5F5;
}

.accordion-item {
    margin: 20px 0px 20px 0px !important;
    border: none;
}

.accordion-button:not(.collapsed) {
    color: #F99853 !important;

}

.shw_mre {
    display: flex;
    align-items: center;
}

.test-cmmnt {
    position: relative;
    left: 36%;
}

.sel_innr_content {
    background-color: #f4f4f4;
}

.footer_l {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
}

.footer_l span {
    font-weight: 600;
    color: #002441;
}

.list-item-inner a:hover {
    color: #F99853;
}

.why_us {
    display: flex;
}

.wh_chs_us_blk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.whs_u_inf {
    width: 80%;
    padding: 3%;
}

.whs_u {
    width: 90%;
}

.whs_u img {
    width: 100%;
    border-radius: 20px;
}

.w_car_info svg {
    color: #F99853;
    font-size: 32px;
}

/* swiper css */

.swiper-scrollbar {
    display: none;
}

.swiper-button-next {
    display: none !important;
}

.swiper-button-prev {
    display: none !important;
}

.scnd_ftr ul li {
    margin-bottom: 6%;
    color: #808080;
}



.bef_elem::after {
    display: block;
    content: "";
    background: #F99853;
    width: 40px;
    height: 2px;
    margin: 10px auto;

}


.dyn_con {

    border-radius: 20px;
    position: relative;
    bottom: 30px;
    background: #fff;
}

.div_for_pde {
    padding: 2% 3% 0% 3%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 0 2%;
    
}

.div_for_padt {
    padding: 2% 6% 0% 6%;
}

.dyn_cont label {
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 13px;
}


.dyn_con_innr {

    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}



.book_hm_inspction {
    display: flex;
    justify-content: center;
    position: relative;
    top: -6px;
}

.dyn_con_innrt {
    display: flex;
    margin: 20px 0px;
}

.dyn_cont {
    display: flex;
    flex-direction: column;
    width: 220px;
}

.dyn_cont input {
    padding: 4px 8px;;
    border-radius: 6px;
    margin-right: 9px;
    border: 1px solid rgb(133, 133, 133);
}

.dyn_cont select {
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 16px;
    border: 1px solid rgb(133, 133, 133);
    color: rgb(133, 133, 133);;
}

/* Modal Body */

.modalbod 
{
    display: flex;
}
.mod_bod_con 
{
    width: 100%;
}


.mod_bod_con input[type=checkbox] {
    accent-color: #f99853;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }  

.mod_bod_con p 
{
    font-weight: 600;
}

.mod_bod_con em a
{
    text-decoration: underline;
    color: #F99853;
}

.mod_mob_num
{
    width: 100%;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #808080;
    border-radius: 5px;
    padding: 12px 14px;
    margin-top: 1%;
    margin-bottom: 2px;
}
.modalbod 
{
    padding: 2%;
    border-radius: 4px;
}
.modal-body 
{
    padding: 5%;
}
  
.mod_mob_num input:focus
{
    outline: none;
}
.algn_txt em {
    margin: 0px 6px;
}
.mt_10 
{
    margin-top: 10px;
    height: 20px;
}
.mod_pop_btn_cntr
{
    display: block;
    margin: 10px auto;
}
.modal_sec 
{
    margin-bottom: 20px;
}
.modal_sec label
{
    color: #002441;
}
.modal_sec span
{
     color: rgb(133, 133, 133);
}
.modal_sec h3
{
    font-size: 24px;
}
.modal_sec h4
{
    font-size: 18px;
    color: #002441;
}
.algn_txt 
{
    display: flex;
    align-items: center;
}

/* Modal Body Ends */

/* Thank you page css */
.car_price_thnky 
{
  text-align: center;
}
.imgchk_box 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgchk_box img 
{
    width: 34%;
    height: auto;
}
.car_prc_unlok_t button
{
    display: block;
    margin: 16px auto;
}
.top_hd_lft p 
{
    color: rgb(133,133,133);
}
.compar_lst 
{
    display: flex;
    justify-content: space-around;
}

.car_prc_unlok 
{
    border: 1px solid #dde5eb;
    border-radius: 10px 0px 0px 10px;
}
.gfe_aftr 
{
    text-align: center;
}
.pricing_page 
{
    margin-top: 6%;
    border-radius: 10px;
}

.mod_bod_con input[type=checkbox] 
{
    height: 20px;
}
.compar_lst 
{
    background-color: #fff;
}

.top_head 
{
    display: flex;
    justify-content: space-between;
    padding: 3%;
    align-items: baseline;
}


.top_hd_rgt a 
{
    padding: 8px 20px;
    border: 1px solid #F99853;
    border-radius: 5px;
    text-decoration: none;
    color: #f99853;
    font-weight: 600;
}

.car_prc_unlok ul 
{
    margin-bottom: 0px;
    padding-left: 0px;
}
.car_prc_unlok ul li
{
   list-style-type: none;
   color: rgb(70, 81, 102);
}

.area_grey 
{
    background-color: grey;
}

.prc_unlck_bx 
{
    background-color: #fff;
}

.prc_unlck_bx_conds 
{
    background-color: #fff;
}
.prc_n_cn
{
    padding: 3%;
    border: 1px solid #dee2e6;
    margin: 3%;
    border-radius: 5px;
}


.compar_lst span a
{
    text-decoration: none;
    font-weight: 700;
    color: #F99853;
    font-size: 20px;
}
.mod_bod_con span svg
{
    color: #00ba67;
    font-size: 24px;
    margin: 0px 6px;
}

.compar_lst .active_cmpr
{
    padding: 4px 10px;
    background-color: #efefef;
    border: 1px solid #F99853;
    border-radius: 4px;
}

.top_hd_lft h3 
{
    color: #002441;
    font-size: 24px;
}
.tt_cr_unlk h3{ 
    font-size: 24px; 
    margin-bottom: 5%;
}

.prc_unlck_bx_conds h4 
{
    color: #002441; 
}

.pricing_page 
{
    display: flex;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.car_prc_unlok 
{
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    border-right: none;
}

.car_prc_unlok_t 
{
    width: 50%;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid #efefef;
}
.car_prc_unlok_t ul li 
{
    list-style-type: none;
}
.car_prc_unlok_t ul 
{
   margin-bottom: 0px;
   padding-left: 10%;
}

.tt_cr_unlk 
{
    padding: 3%;
}

.car_price_thnky p 
{
    font-size: 20px;
}

.wrapper {
	 font-family: 'Helvetica';
	 font-size: 14px;
}
 .StepProgress {
	 position: relative;
	 padding-left: 45px;
	 list-style: none;
}
 .StepProgress::before {
	 display: inline-block;
	 content: '';
	 position: absolute;
	 top: 0;
	 left: 15px;
	 width: 10px;
	 height: 100%;
}
 .StepProgress-item {
	 position: relative;
	 counter-increment: list;
}
 .StepProgress-item:not(:last-child) {
	 padding-bottom: 20px;
}
 .StepProgress-item::before {
	 display: inline-block;
	 content: '';
	 position: absolute;
	 left: -30px;
	 height: 100%;
	 width: 10px;
     border-left: 2px solid #808080;
}
 .StepProgress-item::after {
	 content: '';
	 display: inline-block;
	 position: absolute;
	 top: 0;
	 left: -37px;
	 width: 20px;
	 height: 20px;
	 border: 2px solid #CCC;
	 border-radius: 50%;
	 background-color: #FFF;
}
 .StepProgress-item.is-done::before {
	 border-left: 2px solid #F99853;
}
 .StepProgress-item.is-done::after {
	 content: "✔";
	 font-size: 13px;
	 color: #FFF;
	 text-align: center;
	 border: 2px solid #F99853;
	 background-color: #F99853;
}
 .StepProgress-item.current::before {
	 border-left: 2px solid #F99853;
}
 .StepProgress-item.current::after {
	 content: counter(list);
	 padding-top: 1px;
	 width: 25px;
	 height: 25px;
	 top: -4px;
	 left: -40px;
	 font-size: 14px;
	 text-align: center;
	 color: #F99853;
	 border: 2px solid #F99853;
	 background-color: white;
}
 .StepProgress strong {
	 display: block;
}


/* About Us page CSS */
.abt_row 
{
  height: 200px;
  background-color: #002441;
}

.cont_abt h3
{
    color: #fff;
    font-size: 40px;
}

.brd_crmb_ol li
{
  color: #fff;
  list-style-type: none;
}
.brd_crmb_ol
{
 justify-content: center;
}

.row_cont
{
    margin-bottom: 6%;
    margin-top: 6%;
}

.abt_head_clr h3
{
  color: rgb(70, 81, 102);;
}

.help_nmbr_con 
{
    background-color: #f99853;
    color: #fff;
}
.hlp_call 
{
    text-align: center;
}
.help_nmbr_con 
{
    padding: 6%;
}

.hlp_call svg 
{
    font-size: 36px;
    margin-bottom: 6%;
}

.abt_pnt_descr svg 
{
    color: #F99853;
}

.abt_hd_stl
{
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #002441;
}

.car_man_yr_con 
{
    /* border-top: 1px solid #efefef; */
    width: 538px;
    margin: 2% 0%;
    position: relative
}


/* navigation box for car and brands css */

.main_nav_bx 
{
    width: 538px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    height: 510px;
    overflow: hidden;
    
    background: #fff;
}

.nav_txt 
{
    text-align: center;
}

.brnd_ind_con 
{
    border: 1px solid #efefef;
    border-radius: 6px;
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.top_sec_carsrch 
{
    margin: 5%;
    margin-top: 3%;
    margin-bottom: 0%;
}

.brand_btns 
{
    margin: 1% 1%;
}

.top_sec_carsrch h3
{
    font-weight: 500;
    /* font-size: 30px; */
    line-height: 36px;
    color: #002441;
}

.top_sec_carsrch input
{
    height: 40px;
    width: 90%;
    border-radius: 10px;
    border: 1px solid #808080;
    margin: 2px;
    padding-left: 15px;
}
.top_sec_carsrch input:focus {
    outline: none !important;
    border:1px solid #F99853;
    box-shadow: 0 0 10px #719ECE;
  }

.nav_p_stl 
{
    font-weight: 700;
    color: #F99853;
    margin-top: 0;
    margin-bottom: 3px;
}

.active {
    border-color: #F99853; /* Apply your desired styles */
  }
  .text-white {
    color: #F99853;
  }

/* radio btn css */
.brnd_ind_con input 
{
    display: none;
}

.man_yr_blw_sect 
{
    display: flex;
    flex-direction: column;
}

/* radio btn css ends */

.man_yr_top_sect 
{
    margin: 4%;
}

.man_yr_top_sect input 
{
    width: 90%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #808080;
    margin-top: 4%;
    padding-left: 20px;

}

.man_yr_blw_sectlbl
{
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    height: 40px;
    padding: 1% 0%;
    margin: 2% 8%;
}
.man_yr_blw_sectlbl input[type=radio]
{
    display: none;
}

.man_yr_blw_sectlbl input:checked 
{
  border: 2px solid yellow;
}


.brnd_ind_con input:checked 
{
    border: 1px solid #f99853;
}

.man_yr_blw_sect span 
{
    font-weight: 700;
    color: #F99853
}

.man_yr_mid_sect
{
    font-weight: 700;
    color: #F99853;
}


/* width */
.main_nav_bx::-webkit-scrollbar {
    /* width: 6px; */
    display: none;
  }
  
  /* Track */
  .main_nav_bx::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  
  /* Handle */
  .main_nav_bx::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  .main_nav_bx::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }

  .main_nav_bx input 
  {
    border: 1px solid #e5e5e5;
  }
  
  .main_nav_bx input:focus {
    outline: none !important;
    border:1px solid #F99853;
    box-shadow: 0 0 10px #719ECE;
  }

  /* Radio button styling */

  .brnd_label 
  {
    /* padding: 10px 20px; */
    font-size: 16px;
    font-weight: 600px;
    cursor: pointer;
  }

  #lang-1:checked ~ .label-1, #lang-2:checked ~ .label-2, #lang-3:checked ~ .label-3,
  #lang-4:checked ~ .label-4,  #lang-5:checked ~ .label-5,  #lang-6:checked ~ .label-6,
  #lang-7:checked ~ .label-7,  #lang-8:checked ~ .label-8
  {
    /* border: 2px solid #F99853;
    border-radius: 6px;
    background-color: #F99853; */
    color: #f99853;
  }

  #man_yr input[type=radio] 
  {
    display: none;
  }

  .man_label 
  {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
  }

  .lang-lb-1:checked ~ .m_lbl_label-1
  {
    border: 2px solid #F99853;
  }

  #rto_states input[type=radio]
  {
    display: none;
  }

  .rto_label 
  {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
  }

  #rto-1:checked ~ .rto_label_1, #rto-2:checked ~ .rto_label_2, 
  #rto-3:checked ~ .rto_label_3, #rto-4:checked ~ .rto_label_4, 
  #rto-5:checked ~ .rto_label_5 
  {
    border: 2px solid #F99853;
  }

  .lbl_km_drv 
  {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
  }

  #km_driven input[type=radio]
  {
    display: none;
  }

  #km_driv_1:checked ~ .km_driv_lbl_1, #km_driv_2:checked ~ .km_driv_lbl_2,
  #km_driv_3:checked ~ .km_driv_lbl_3, #km_driv_4:checked ~ .km_driv_lbl_4,
  #km_driv_5:checked ~ .km_driv_lbl_5
  {
    border: 2px solid #F99853;
  }

  #city_location input[type=radio]
   {
    display: none;
   }

   .city_lbl 
   {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
   }

   #city_1:checked ~ .city_lbl_1, #city_2:checked ~ .city_lbl_2,
   #city_3:checked ~ .city_lbl_3, #city_4:checked ~ .city_lbl_4,
   #city_5:checked ~ .city_lbl_5
   {
     border: 2px solid #F99853;
   }

   #car_models input[type=radio]
   {
      display: none;
   }

   .car_mod
   {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
   }

   #car_mod_1:checked ~ .car_mod_lbl_1, #car_mod_2:checked ~ .car_mod_lbl_2,
   #car_mod_3:checked ~ .car_mod_lbl_3, #car_mod_4:checked ~ .car_mod_lbl_4,
   #car_mod_5:checked ~ .car_mod_lbl_5
   {
     border: 2px solid #F99853;
   }

   #fuel_type input[type=radio]
   {
      display: none;
   }

   .flttp 
   {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
   }

   #fl_tp_1:checked ~ .flttp_lbl_1, 
   #fl_tp_2:checked ~ .flttp_lbl_2
   {
     border: 2px solid #F99853;
   }


   .state_stl 
   {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin: 2% 8%;
   }

   #states input[type=radio]
   {
     display: none;
   }

   #state_1:checked ~ .state_stl_1, #state_2:checked ~ .state_stl_2, 
   #state_3:checked ~ .state_stl_3, #state_4:checked ~ .state_stl_4, 
   #state_5:checked ~ .state_stl_5 
   
   {
     border: 2px solid #F99853;
   }




   .bck_btn 
   {
    border: 1px solid #e5e5e5;
    width: 10%;
    height: 30px;
    margin: 3%;
    background: #e5e5e5;
    color: #f99853;
    border-radius: 4px;
   }

   .top_navscrl_sec 
   {
    border-bottom: 1px solid #efefef;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
   }
   .top_navscrl_sec ul
    {
        padding-left: 0px;
        margin-bottom: 0px;
    }
   .top_navscrl_sec ul li 
   {
    list-style-type: none;
    margin: 0% 2%;
    padding: 1% 10%;
    background-color: #fafafa;
    border: 1px solid #f99853;
    border-radius: 6px;
   }
   
   .top_navscrl_sec ul li a
   {
    text-decoration: none;
    color: #000;
   }
    


   /* width */
   .top_navscrl_sec::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  /* Track */
  .top_navscrl_sec::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
    height: 2px;
  }
  
  /* Handle */
  .top_navscrl_sec::-webkit-scrollbar-thumb {
    background: #f99853;
    border-radius: 3px;
  }
  
  /* Handle on hover */
  .top_navscrl_sec::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }

  .man_yr_top_sect 
  {
    position: relative;
  }
   
  .man_yr_top_sect svg
  {
    position: absolute;
    bottom: 10%;
    left: 4%;
    color: #f99853;
  }
  .cars_filter 
  {
      background-color: #fff;
      margin-top: 10px;
      width: 538px;
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
}  
.brand_tiles{
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.brands-active {
    border-color: #F99853;
    color: #F99853;
}

.car_man_yr_con span {
    padding: 6px 0px 0px 20px;
    background: transparent;
    float: left;
    color: #F99853;
    position: absolute;
    left: 0;
    z-index: 999;
}

.send-otp-anc {
    font-weight: bold;
    font-size: 17px;
    color: #F99853;
}

.top_hd_lft 
{
    width: 70%;
}
.top_hd_rgt {
    width: 30%;
    display: flex;
    justify-content: end;
}
.active-tiles{
    background-color: #F99853 !important;
    /* list-style-type: none; */
    margin: 0% 3%;
    padding: 1% 15%;
    border-radius: 6px;
}



/* width */
.cars_filter::-webkit-scrollbar {
    width: 6px;
  
  }
  
  /* Track */
  .cars_filter::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  
  /* Handle */
  .cars_filter::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  .cars_filter::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
    
  }