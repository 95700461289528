@media screen and (min-width: 0px) and (max-width:600px) {
    body {
        overflow-x: hidden;
        width: 100%;
    }

    html {
        overflow-x: hidden;
        width: 100%;
    }

    .strt-nav img {
        margin-left: 0%;
    }

    .tob-bar-nav {

        display: flex;
        flex-direction: column;
    }

    .strt-nav {
        width: unset;
    }

    .mid-menu-nav ul li a {
        font-size: 12px;
    }

    .mid-menu-nav {
        width: 100%;
    }

    .mid-menu-nav ul {

        margin-top: 2%;
        padding: 0px;
        justify-content: center;
    }

    .mid-menu-nav ul li {
        list-style-type: none;
        padding: 1% 4%;
        margin: 0px 12px;
    }



    .hero-title_1 {
        width: unset;
        margin-left: 0px;
    }



    .hero-title_1 h1 {
        font-size: 32px;
        line-height: 38px;
    }

    .hero-title_1 p {
        width: unset;
    }

    .dyn_cont label {
        font-size: 12px;
    }

    .dyn_cont select {
        width: 98%;
        font-size: 16px;
        height: 42px;
    }

    .dyn_cont input {
        margin-right: 0px;
    }

    .dyn_con_innr {
        display: flex;
        margin: 20px 0px;
        flex-direction: column;
        padding: 0 14%;
    }

    .div_for_pde {
        width: 96%;
    }

    .dyn_con_innrt {
        display: flex;
        margin: 20px 0px;
        flex-direction: column;
    }

    .row_sell_step {
        flex-direction: column;
    }

    .row_sell_step-cons {
        width: 100%;
    }

    .why_us {
        flex-direction: column;
    }

    .wh_chs_us_blk {
        width: 100%;
    }

    .whs_u_inf {
        width: unset;
        padding: unset;
    }

    .only_carcon {
        margin-left: 0px;
    }


    .section_car {
        flex-direction: column;
    }


    .only_carcon h1 {
        font-size: 18px;
        line-height: 24px;
        margin: 0px;
    }

    .only_carcon_img img {
        width: 100%;
    }

    .only_carcon br {
        display: none;
    }

    .mid_size_secbtn {
        height: 40px;
        margin-top: 10px;
    }

    .w_car_info h3 {
        font-size: 18px;
    }

    .footr_ h3 {
        font-size: 12px;
    }

    .footer_l span {
        text-align: center;
    }

    .footr_con {
        flex-direction: column;
    }

    .footr_ {
        width: unset;
    }

    .ft_in {
        width: 100%;
    }

    .swiper-slide {
        width: 100% !important;
    }

    .hero-title_1 p {
       
        font-size: 16px;
        line-height: unset;
    }

    .dyn_cont input { 
        padding: 6px 10px;
    }



    /* Modal Popup */

    .mod_car_price svg {
        font-size: 18px;
    }


    /* Thank You Page*/

    .pricing_page
    {
        flex-direction: column;
    }   
    
    .car_prc_unlok
    {
     width: 100%;
     }

     .car_prc_unlok_t 
     {
        width: 100%;
     }

     .mod_car_price span {
        font-size: 16px;
    }
    .tt_cr_unlk {
        padding: 6%;
    }


    .compar_lst span a {
        color: #F99853;
        font-size: 16px;
    }

    .top_hd_rgt a
    {
        padding: 6px 12px;
    }
    .top_head 
    {
        display: block;
        margin-bottom: 8%;
    }

    .car_prc_unlok 
    {
        border: none;
    }
    .car_prc_unlok_t 
    {
        border-radius: none;
    }
    .mt_10 svg 
    {
        color: #F99853;
    }

    .mt_10 em 
    {
        color: #F99853;
    }

    .dyn_cont
    {
        margin-bottom: 4%;
    }

    .img_con img
    {
        width: 100%;
    }
    .help_nmbr_con h2 
    {
        text-align: center;
    }

    .sect_con_nav 
    {
        width: 100%;
    }
    .car_man_yr_con
    {
        width: 100% !important;
    }

    .top_navscrl_sec ul li 
    {
        padding: 1% 3%;
    }

    .brnd_label 
    {
        font-size: 12px;
    }

    .cars_filter 
    {
        width: unset;
    }

    .stprt {
        text-align: center;
        margin-bottom: 0px;
        padding: 1%;
        font-size: 16px;
        color: #fff;
        position: absolute;
        padding: 10px;
        background: #f99853;
        border-radius: 50%;
        width: 13%;
        right: 2%;
        bottom: 38%;
    }

    .modal-title h3 
    {
        font-size: 18px;
    }

    .modal_sec h3 {
        font-size: 18px;
    }

    .modal_sec span{
        font-size: 11px;
    }

    .top_hd_lft h3 
    {
        font-size: 20px;
    }

    .car_price_thnky h3 div 
    {
        font-size: 18px;
    }
}